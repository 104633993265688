.star-add-container {
  position: absolute;
  top: 104px;
  left: 0px;
  z-index: 100;
}

.jinfen-item-container:last-child {
  margin-right: 0px;
}

.jinfen-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8484841a;
  padding-top: 8px;
  padding-bottom: 8px;
  flex: 1;
  margin-right: 9px;
  border-radius: 8px;
  &.jinfen-item-active {
    outline: 1px solid #ff3f9c;
  }
  & > .jinfen-item-price-text {
    font-size: 14px;
    color: #ffffff;
    padding-top: 8px;
  }
  & > .jinfen-item-description-text {
    font-size: 12px;
    color: #979797;
  }
}

.jinfen-button-container {
  margin-top: 10px;
  margin-bottom: 30px;
  & > .jinfen-button {
    height: 44px;
    margin: 0px 16px;
    border-radius: 8px;
    background: #f3a127;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
  }
}

.jinfen-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  & > .jinfen-title {
    font-size: 17;
    color: #ffffff;
    align-self: center;
    margin-top: 20px;
  }
  & > .jinfen-close {
    position: absolute;
    top: 20px;
    right: 16px;
  }
  & > .jinfen-show-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding: 18px 16px 0px 16px;
    & > .jinfen-show-container-count-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > .jinfen-show-container-count {
        color: white;
        margin-left: 4px;
      }
    }
  }
}

.shengdou-container-count {
  color: white;
  margin-left: 4px;
  margin-right: 8px;
}

.jinfen-get-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 16px 16px 16px;
}

.star-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: hsla(83, 100%, 30%, 0.9);
  padding: 4px 5px 4px 0px;
  border-radius: 0px 23px 23px 0px;
  z-index: 999px;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
}

.star-count {
  font-size: 15px;
  color: #ffffff;
  padding-left: 2px;
  padding-right: 10px;
}

.game-tip {
  align-self: center;
}

.game {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  & > .game_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px 16px;
    width: 100%;
    height: max-content;
    // background-color: #25760d;
      background-color: #028aca;
    z-index: 400;
    & > .brand {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > .title {
        margin: 0;
        font-size: 19px;
        font-weight: 600;
        // color: moccasin;
        color: #fff;
        line-height: 25px;
      }
      & > .rule {
        margin-left: 6px;
        min-width: 18px;
        width: 18px;
        height: 18px;
      }
    }
    & > .action {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px;
      // background-color: rgb(0, 100, 0);
        background-color: #315f96;
      border-radius: 50px;
      & > .action_item {
        width: max-content;
        height: max-content;
        font-size: 14px;
        font-weight: 400;
        // color: #c3dea4;
        color: #fff;
        line-height: 20px;
        &.divider {
          margin: 0 4px;
          background-color: transparent;
        }
      }
    }
  }
  & > .game_main {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: url("../../assets/Sushiimg/backgrounds.png");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    & > .shitou {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50px;
      z-index: 500;
    }
    & > .sum {
      position: absolute;
      top: 24px;
      right: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1px 2px 1px 3px;
      background-color: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(177, 244, 89, 0.6);
      border-radius: 12px;
      z-index: 20;
      & > .icon {
        min-width: 17px;
        width: 17px;
        height: 17px;
      }
      & > .add {
        min-width: 21px;
        width: 21px;
        height: 21px;
      }
      & > span {
        text-align: center;
        min-width: 42px;
        font-size: 13px;
        font-weight: 400;
        color: #497d28;
      }
    }
    & > .animal {
      position: absolute;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 96px;
      height: 96px;
      // background-color: #fff;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      // opacity: 0.5;
      transition-property: top, left;
      transition-timing-function: linear;
      z-index: 8;
      & > .animal_container {
        position: absolute;
        top: -12px;
        left: 50%;
        padding: 1px;
        width: 70%;
        height: 10px;
        transform: translateX(-50%);
        background-color: moccasin;
        box-shadow: 1px 1px 2px hsla(0, 0%, 0%, 0.25);
        border-radius: 5px;
        overflow: hidden;
        & > .animal_container_progress {
          width: 100%;
          height: 100%;
          background-color: #ff4f1c;
          border-radius: 5px;
        }
      }
      & > img {
        min-width: 100%;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.video_box{
  position: absolute;
  width: 100%;
  height: 100%;
}

// 猫猫寿司
.cat_box {
  position: absolute;
  width: 170px;
  height: 170px;
  left: 50%;
  margin-left: -85px;
  top: 164px;

  img {
    width: 100%;
    height: 100%;
  }
}
.left_box {
  position: absolute;
  min-width: 120px;
  height: 50px;
  top: 235px;
  left: 10px;
  background-image: url("../../assets/Sushiimg/leftImg.png");
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF5D6;
  font-size: 16px;
  text-shadow:
    -1px -1px 0 #5D2609,
    1px -1px 0 #5D2609,
    -1px 1px 0 #5D2609,
    1px 1px 0 #5D2609;

  .add_box {
    position: absolute;
    right: 20px;
    color: #FFF5D6;
    font-size: 20px;
    font-weight: normal;
    margin-top: -5px;
    text-shadow:
      -1px -1px 0 #5D2609,
      1px -1px 0 #5D2609,
      -1px 1px 0 #5D2609,
      1px 1px 0 #5D2609;

  }
}

.right_box {
  position: absolute;
  width: 100px;
  height: 50px;
  top: 235px;
  right: 0px;
  background-image: url("../../assets/Sushiimg/rightImg.png");
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .right_1 {
    width: 20px;
    height: 20px;
  }
}
// .start_box {
//   position: absolute;
//   width: 65px;
//   height: 65px;
//   top: 157px;
//   left: 40px;
//   background-image: url("../../assets/Sushiimg/timeImg.png");
//   background-size: 100% 100%;
//   background-position: center;
//   line-height: 60px;
// .start_text {
//     font-size: 16px;
//     color: #FFF5D6;
//     font-family: 'fangzhen';
//     //  background-color: red;
//     width: 25px;
//     height: 30px;
//     margin-left: 20px;
//     margin-top: 2px;
//     text-align: center;
//     text-shadow:
//       -1px -1px 0 #5D2609,
//       1px -1px 0 #5D2609,
//       -1px 1px 0 #5D2609,
//       1px 1px 0 #5D2609;

//   }
// }


.main_box {
  position: absolute;
  width: 92%;
  top: 320px;
  left: 5%;

  .main_box_gift {
    display: flex;
    flex-wrap: wrap;
    gap: 0 15px;
    width: 100%;
    
  }
}
.current_box{
    position: absolute;
    width: 90%;
        height: 40px;
        top: 485px;
        // right: 0px;
    left: 5%;
    // background-image: url("../../assets/Sushiimg/currentImg.png");
    //     background-size: 100% 100%;
    //     background-position: center;
        display: flex;
        align-items: center;
       padding-left: 20%;
    color: #FFF5D6;
        font-family: 'fangzhen';
        .current_outing{
            display: flex;
                align-items: center;
                width: 95%;
            overflow: hidden;
            overflow-x: auto;
        }
        .current_outing::-webkit-scrollbar {
                  display: none;
                  /* 隐藏滚动条 */
                }
        //轮播滚动
        .current_out{
            /* 每部分占容器宽度的一半 */
            min-width: 60px;
            // background-color: red;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 1px;
            img{
                width: 25px;
            }       
        } 
                @keyframes marquee {
                  from {
                    transform: translateX(0);
                  }
        
                  to {
                    transform: translateX(-50%);
                  }
                }
        // .current_out:nth-child(10){
        //     margin-left: 60px;
        // }
        .current_number{
            margin-left: 2px;
            color: #FFF5D6;
            text-shadow:
                    -1px -1px 0 #5D2609,
                    1px -1px 0 #5D2609,
                    -1px 1px 0 #5D2609,
                    1px 1px 0 #5D2609;
        }
        
}
.video_out {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;

}
.history_box {
  position: absolute;
  width: 65%;
  height: 50px;
  bottom: 40px;
  // right: 0px;
  left: 5%;
  // background-image: url("../../assets/Sushiimg/historyImg.png");
  // background-size: 100% 100%;
  // background-position: center;
  line-height: 45px;
  margin-left: 20%;
  display: flex;
  align-items: center;
  // gap: 10px;
  overflow-x: auto;

  // background-color: red;
  .history_img {
    // width: 95%;
    width: 30px;
    margin-right: 6px;
  }
}
 .history_box::-webkit-scrollbar {
   display: none;
   /* 隐藏滚动条 */
 }
 .energy_out{
  position: absolute;
  top: 80px;
  right: 15px;
 }
 .energy_text{
  position: absolute;
  font-size: 13px;
    font-weight: 400;
    // color: #fbf59b;
    color: #fff5d6;
    text-shadow: -1px -1px 0 #5d2609, 1px -1px 0 #5d2609, -1px 1px 0 #5d2609,
      1px 1px 0 #5d2609;
      writing-mode: vertical-rl;
        /* 竖排文字，从右到左 */
        text-align: center;
        /* 可选，居中对齐 */
        bottom: 40px;
        left: -13px;
 }
 .energy_number{
    width: 34px;
    text-align: center;
  position: absolute;
    font-size: 13px;
    font-weight: 400;
    // color: #fbf59b;
    color: #fff5d6;
    text-shadow: -1px -1px 0 #5d2609, 1px -1px 0 #5d2609, -1px 1px 0 #5d2609,
      1px 1px 0 #5d2609;
      bottom: 12px;
      left: 4px;
 }
 .outbox_img{
  position: relative;
  width: 45px;
  height: 150px;
  background-image: url("../../assets/images/energyImg/outBox2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
 }
 .kong_box{
  position: relative;
  top: 4px;
  left: 13px;
  width: 17px;
  height: 102px;
  background-image: url("../../assets/images/energyImg/kongImg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
 }
 .value_box{
  position: absolute;
  bottom: 0;
  width: 17px;
  // height: 0%;
    background-image: url("../../assets/images/energyImg/man.png");
    background-repeat: no-repeat;
    background-size: 100% ;
    border-radius:0 0px 6px 6px;
 }
 input[aria-hidden=true] {
   display: none !important;
 }
@primary-color: #1DA57A;