.show_game_end_model {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 998;
}
.show_game_end_model > .show_game_end_model_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 16px;
  background-color: rgba(229, 186, 123, 0.9);

  /* border: 2px solid rgba(38, 119, 13, 0.72); */
  border-radius: 8px;
  /* background-image: url("../../../../assets/Sushiimg/leftImg.png");
  background-size: 100% 100%; */
}
.show_game_end_model > .show_game_end_model_box > .show_game_end_model_content {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  text-shadow: -1px -1px 0 #5d2609, 1px -1px 0 #5d2609, -1px 1px 0 #5d2609,
    1px 1px 0 #5d2609;
  line-height: 24px;
}
