.boss_house {
  position: absolute;
  top: 1.905%;
  left: 0;
  width: 107px;
  height: 166px;
  // background-color: #eaea;
  background-image: url("../../../../assets/images/mapImg/hoome@2x.png");

  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 10;
  // boss屋顶 进度条
  .progress {
    position: absolute;
    top: 5px;
    left: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > .boss_text {
      width: 66px;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      text-shadow: 1px 1px 0px #65130d;
      white-space: nowrap;
      transform: scale(0.83333, 0.83333);
      line-height: 10px;
      // text-stroke: 1px #65130d;
      // -webkit-text-stroke: 1px #65130d;
    }
    & > .boss_progress {
      position: relative;
      margin: 2px;
      padding: 1px;
      width: 55px;
      height: 10px;
      background-color: #753c22;
      border-radius: 5px;
      & > .progress_value {
        width: 0%;
        height: 100%;
        background-color: #ffea6b;
        transition: width 300ms;
        border-radius: 4px;
      }
      & > .progress_text {
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 12px;
        font-weight: 400;
        color: #c19015;
        // text-shadow: 0 0 10px #c19015;
        white-space: nowrap;
        transform: translate(-50%, -50%) scale(0.66666, 0.66666);
        line-height: 8px;
      }
    }
  }
}

@primary-color: #1DA57A;