.countdown {
  // position: absolute;
  // top: 20px;
  // left: 50%;
    position: absolute;
      top: 137px;
      left: 70px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  & > .countdown_text {
    font-size: 13px;
    font-weight: 400;
    // color: #fbf59b;
    color: #fff5d6;
      text-shadow: -1px -1px 0 #5d2609, 1px -1px 0 #5d2609, -1px 1px 0 #5d2609,
        1px 1px 0 #5d2609;
  }
  & > .countdown_progress {
    margin-top: 4px;
    padding: 1px;
    width: 98px;
    height: 10px;
    background-color: #497d28;
    border-radius: 5px;
    & > .progress_value {
      position: relative;
      width: 0%;
      height: 100%;
      background-color: #f8e773;
      border-radius: 10px;
      & > .progress_icon {
        position: absolute;
        top: -50%;
        right: 0;
        transform: translate(50%);
        width: 12px;
        height: 14px;
      }
    }
  }
}

.start_box {
  width: 65px;
  height: 65px;
  background-image: url("../../../../assets/Sushiimg/timeImg.png");
  background-size: 100% 100%;
  background-position: center;
  line-height: 60px;

}

.start_text {
  font-size: 16px;
  color: #FFF5D6;
  font-family: 'fangzhen';
  //  background-color: red;
  width: 25px;
  height: 30px;
  margin-left: 20px;
  margin-top: 2px;
  text-align: center;
  text-shadow:
    -1px -1px 0 #5D2609,
    1px -1px 0 #5D2609,
    -1px 1px 0 #5D2609,
    1px 1px 0 #5D2609;

}
@primary-color: #1DA57A;